'use strict'

/**
 * Object with all validations of the login form inputs.
 *
 * @author Andres Correa
 * @since 31/12/2019 3:20 PM
 * @version 1.0.0
 */
export default {
  password: {
    required: true,
    min: 8,
    max: 50,
    password_format: true
  },
  email: {
    required: true,
    email: true,
    max: 50
  },
  mdfField: {
    required: true,
    max: 6
  }
}
