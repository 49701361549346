<template>
  <div class="b-authetication d-flex flex-column flex-lg-row justify-center justify-lg-space-around no-gutters">
    <div class="col-lg-5 d-flex justify-center align-center">
      <v-container class="text-center">
        <v-row
          justify="center"
        >
          <v-col
            align-self="center"
            cols="10"
            sm="6"
            lg="9"
          >
            <ValidationObserver
              ref="observer"
              v-slot="{ handleSubmit }"
            >
              <v-form
                name="formulario-iniciar-sesion"
                @submit.prevent="handleSubmit(authenticateUser)"
              >
                <app-text-field-with-validation
                  id="userNameField"
                  v-model="model.username"
                  :rules="authenticationValidations.email"
                  :label="$t('form.label_email').toLowerCase()"
                  :label-input="$t('form.label_email')"
                  class="required"
                  name="userName"
                  type="text"
                />
                <app-text-field-with-validation
                  id="passwordField"
                  v-model="model.password"
                  :rules="authenticationValidations.password"
                  :label="$t('form.password').toLowerCase()"
                  :label-input="$t('form.password')"
                  class="required"
                  name="password"
                  type="password"
                />
                <app-text-field-with-validation
                  id="mdfField"
                  v-model="model.validationCode"
                  :rules="authenticationValidations.mdfField"
                  :label="$t('form.mdfField').toLowerCase()"
                  :label-input="$t('form.mdfField')"
                  class="required"
                  name="mdfField"
                  type="number"
                />
                <input
                  id="honeypot"
                  v-model="model.noRobot"
                  name="honeypot"
                  type="hidden"
                  value=""
                >
                <div class="d-flex justify-center justify-space-around mt-5">
                  <v-btn
                    id="login"
                    color="primary"
                    type="submit"
                    block
                  >
                    {{ $t('btn.login') }}
                  </v-btn>
                </div>
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-spacer />
                  <v-col
                    class="py-0"
                    cols="12"
                    sm="8"
                    md="5"
                  >
                    <v-btn
                      id="btnRegisterUser"
                      class="text-none"
                      :to="{ name: 'RegisterNewUser' }"
                      color="primary"
                      text
                      large
                    >
                      {{ $t('btn.register_user') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import authenticationMixin from '../../../../../mixins/authenticationMixin'
import handleErrorMixin from '../../../../../mixins/handleErrorMixin'
import { URL_AUTHENTICATE_FRONTOFFICE } from './data/authenticateData'
import { UNAUTHORIZED, FORBIDDEN, BAD_REQUEST } from '@/shared/constants/httpStatus'
import authenticationValidations from './data/authenticationValidations'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST, SHOW_ALERT } from '@/store/mutations-types'

/**
 * Componente para autenticacion frontOffice.
 *
 * @author Juan P. Bolivar
 * @version 1.0.0
 * @since 29/04/2020
 */
export default {
  name: 'AuthenticationFrontoffice',
  mixins: [authenticationMixin, handleErrorMixin],
  data () {
    return {
      authenticationValidations,
      model: {
        username: '',
        password: '',
        noRobot: null,
        validationCode: ''
      }
    }
  },
  methods: {
    /**
     * This method logs in to the app
     * Metodo de autenticacion de usuarios
     *
     * @author Juan P. Bolivar
     * @version 1.0.0
     * @since 29/04/2020
     */
    authenticateUser () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.post(URL_AUTHENTICATE_FRONTOFFICE, this.model).then((response) => {
        this.$_authenticationMixin_persistUserInformation(response.data)
      }).catch((error) => {
        if (error !== null && error.response.status === UNAUTHORIZED) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('form.wrong_user'),
            show: true
          })
          return
        }
        if (error.response.status === FORBIDDEN) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('form.register_not_complet'),
            show: true
          })
          return
        }
        if (error.response.status === BAD_REQUEST) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: error.response.data.errorMessage,
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    }
  }
}
</script>
<style lang="scss">
.b-authetication{
  background-image: url('../../../../../assets/wallpaper_authentication.png');
  background-size: 100% 100%;
}
.v-btn.primary > span {
  color: #424242;
}
/* Animación para ocultar estilos de autollenado chrome */
@-webkit-keyframes autofill {
   to {
        color: #222;
        background: transparent;
    }
}
input:-webkit-autofill {
  -webkit-animation-delay: 0s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
</style>
<i18n src="./data/i18nMessage.json" />
